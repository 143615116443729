import { defineNuxtRouteMiddleware } from "nuxt/app";
export default defineNuxtRouteMiddleware(async (to, from) => {
  const { getToken } = useApollo();
  const token = await getToken();

  const hasToken = !!token;

  if (hasToken) {
    console.log("Existing token, redirecting to home page.");
    return navigateTo("/");
  }
});
